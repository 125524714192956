import { FC } from "react";
import Typography from "@mui/material/Typography";

import { UnitTierInformation } from "../../components/UnitTierInformation";
import { IUnitsInformation } from "../../components/UnitsInformation";
import { Forms } from "./Forms";
import { IFormData, IReservationForm } from "./type";

interface ISelection {
  selectedUnit: IUnitsInformation;
  selectedTier?: string;
  onSelectTier: (tierId: string) => void;
  formType: IReservationForm["typeOfForm"];
  onFormSubmit: (formData: IFormData) => void;
  onFormSecondarySubmit: (formData: IFormData) => void;
}

export const Selection: FC<ISelection> = (props) => {
  return (
    <>
      {props.selectedUnit.tiers
        .filter((x) => x.availableUnitsCount >= 1)
        .map((tier) => (
          <UnitTierInformation
            key={tier.id}
            tierInfo={tier}
            selected={props.selectedTier === tier.id}
            onSelect={() => props.onSelectTier(tier.id)}
          />
        ))}
      <Typography variant="h6" gutterBottom>
        {props.formType === "contactUs"
          ? "Send a message"
          : "Contact Information"}
      </Typography>
      <Forms
        typeOfForm={props.formType}
        formData={{} as IFormData}
        onFormDataSubmit={props.onFormSubmit}
        onFormDataSecondarySubmit={props.onFormSecondarySubmit}
      />
    </>
  );
};
