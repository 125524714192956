import { FC, useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import TextField from "@mui/material/TextField";
// import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { IReservationForm, IShortForm } from "../type";
import { DATE_FORMAT, EVENTS } from "../../../constants";
import { useConfig } from "../../../context/ConfigProvider";
import { useUnitInformation } from "../../../context/UnitsInformationProvider";
import { TextMaskCustom } from "../../../components/PhoneNumberField";
// import Box from "@mui/material/Box";

export const ShortForm: FC<Omit<IReservationForm<IShortForm>, "typeOfForm">> = (
  props
) => {
  const { triggerEvent, configuration } = useConfig();
  const { reserving } = useUnitInformation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IShortForm>({
    defaultValues: {
      firstName: props.formData?.firstName || "",
      lastName: props.formData?.lastName || "",
      email: props.formData?.email || "",
      phoneNumber: props.formData?.phoneNumber || "",
      moveInDate: props.formData?.moveInDate || moment().format(DATE_FORMAT),
    },
  });
  // console.error(errors);

  const onSubmit: SubmitHandler<IShortForm> = props.onFormDataSubmit;

  useEffect(() => {
    triggerEvent(EVENTS.ON_RESERVATION_FORM_VALIDATION_ERROR, errors);
  }, [errors]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Controller
          name="firstName"
          rules={{
            required: { value: true, message: "First Name is required" },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete="given-name"
                label="First Name"
                placeholder="First Name"
                required
                error={errors.firstName ? true : undefined}
                helperText={errors.firstName?.message || ""}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="lastName"
          rules={{
            required: { value: true, message: "Last Name is required" },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete="family-name"
                label="Last Name"
                placeholder="Last Name"
                required
                error={errors.lastName ? true : undefined}
                helperText={errors.lastName?.message || ""}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="email"
          rules={{
            required: { value: true, message: "Email is required" },
            pattern: {
              value:
                /^(?!\.)(?!.*\.{2})([a-zA-Z0-9._+-]+(?<!\.))@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete="email"
                label="Email"
                placeholder="Email"
                required
                error={errors.email ? true : undefined}
                helperText={errors.email?.message || ""}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="phoneNumber"
          rules={{
            required: { value: true, message: "Phone Number is required" },
            maxLength: { value: 14, message: "Invalid Phone Number" },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              {/* <FormControl
                variant="outlined"
                error={errors.phoneNumber ? true : undefined}
                // helperText={errors.phoneNumber?.message || ""}
              >
                <InputLabel htmlFor="formatted-text-mask-input">
                  Phone Number
                </InputLabel>
                <Input
                  fullWidth
                  inputRef={ref}
                  {...otherFieldProps}
                  autoComplete="tel"
                  required
                  placeholder="Phone Number"
                  id="formatted-text-mask-input"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent={TextMaskCustom as any}
                />
              </FormControl> */}
              {/* <Box sx={{ display: "none" }}>
                <Input
                  fullWidth
                  inputRef={ref}
                  {...otherFieldProps}
                  autoComplete="tel"
                  required
                  placeholder="Phone Number"
                  id="formatted-text-mask-input"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent={TextMaskCustom as any}
                />
              </Box> */}
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete="tel"
                label="Phone Number"
                placeholder="Phone Number"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                InputProps={{ inputComponent: TextMaskCustom as any }}
                required
                error={errors.phoneNumber ? true : undefined}
                helperText={errors.phoneNumber?.message || ""}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="moveInDate"
          control={control}
          rules={{
            required: { value: true, message: "Move in date is required" },
            validate: (value) => {
              if (!value) return true;
              // if(moment(value, DATE_FORMAT).isValid())
              //   return "Move in date should be valid date";
              if (moment(value, DATE_FORMAT).isBefore(moment(), "date"))
                return "Move in date should be today or after today";
            },
          }}
          render={({ field: { ref, onChange, value, ...otherProps } }) => (
            <Grid item xs={12}>
              <DatePicker
                {...otherProps}
                inputRef={ref}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: errors.moveInDate ? true : undefined,
                    helperText: errors.moveInDate?.message || "",
                    inputRef: ref,
                    required: true,
                  },
                  inputAdornment: { position: "start" },
                }}
                value={moment(value, DATE_FORMAT)}
                onChange={(data) => data && onChange(data.format(DATE_FORMAT))}
              />
            </Grid>
          )}
        />
        <Controller
          name="promoCode"
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete="tel"
                label="Promo Code"
                placeholder="Promo Code"
                error={errors.promoCode ? true : undefined}
                helperText={errors.promoCode?.message || ""}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        {configuration.enableMoveIn && (
          <Grid item xs={12}>
            <Button
              fullWidth
              disabled={reserving}
              variant="contained"
              onClick={handleSubmit(props.onFormDataSubmit)}
            >
              Rent Now
              {reserving && <CircularProgress size={20} sx={{ mx: 1 }} />}
            </Button>
          </Grid>
        )}
        {!configuration.forceMoveIn && (
          <Grid item xs={12}>
            <Button
              disabled={reserving}
              fullWidth
              onClick={handleSubmit(props.onFormDataSecondarySubmit!)}
            >
              Reserve this unit
              {reserving && <CircularProgress size={20} sx={{ mx: 1 }} />}
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
